import { AddressService } from './../services/address/address.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'provincePipe'
})
export class ProvincePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): string {
    const provinces = AddressService.getProv();
    if (value!==null&&value!==undefined) {
      return provinces.find(p=>p.IdProvince === value.IdProvince).Name;
    }
    return '';
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tablePipe'
})
export class TablePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    switch (value) {
      case 'Services':
        return 'Servicio';
      case 'AreasServices':
        return 'Servicio > Áreas';
      case 'Contracts':
        return 'Servicio > Contrato';
      case 'MobileTeamTasks':
        return 'Servicio > Tareas especiales';
      case 'Supplies':
        return 'Servicio > Materiales';
      case 'Customers':
        return 'Cliente';
      case 'Works':
        return 'Trabajo';
      case 'Employees':
        return 'Empleado';
      case 'AreasEmployees':
        return 'Empleado > Áreas';
      case 'Vacations':
        return 'Empleado > Vacaciones';
      case 'Domiciles':
        return 'Dirección';
      default:
        return '-';
    }
  }

}

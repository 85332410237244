import { ProvincePipe } from './province.pipe';
import { ActionsPipe } from './actions.pipe';
import { TablePipe } from './table.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [TablePipe, ActionsPipe, ProvincePipe],
  exports: [TablePipe, ActionsPipe, ProvincePipe],
  imports: [
    CommonModule
  ]
})
export class PipeModuleModule { }

import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-excel-modal',
  templateUrl: './excel-modal.component.html',
  styleUrls: ['./excel-modal.component.scss'],
})
export class ExcelModalComponent implements OnInit {

  columns: [];
  form: FormGroup;
  ready = false;
  constructor(public dialogRef: MatDialogRef<ExcelModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.columns = this.data.excelColumns;
    console.log(this.data.excelColumns);
    this.form = new FormGroup({});
    this.columns.map((d: any) => {
      this.form.addControl(d.column, new FormControl(false));
    });
    this.ready = true;
  }
  toggle() {
    this.columns.map((d: any) => {
      this.form.controls[d.column].setValue(!this.form.controls[d.column].value);
    });
  }
  download() {
    let s = '';
    Object.keys(this.form.value).map(d => {
      if (this.form.value[d]) {
        s += d + ', ';
      }
    });
    this.dialogRef.close({ columns: s.slice(0, -2) });
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { filter, map, take } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MasterGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router, private toastController: ToastController) {
  }

  canActivate(): Observable<boolean> {
    return this.authService.role.pipe(
      take(1),
      map(data => {
        if (data !== 'MASTER') {
          this.router.navigate(['/services']);
        }
        return data === 'MASTER';
      })
    );
  }
}

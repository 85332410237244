import { Injectable } from '@angular/core';

import { Plugins } from '@capacitor/core/';
import { BehaviorSubject, from, Observable, of, Subject, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

// eslint-disable-next-line @typescript-eslint/naming-convention
const { Storage } = Plugins;

const TOKEN_KEY = 'serfeyAuth';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  token = null;
  role = new BehaviorSubject<string>(null);

  constructor(private http: HttpClient) {
    this.loadToken();
  }

  /**
   * Gets token from storage
   */
  static async getToken() {
    const token = await Storage.get({ key: TOKEN_KEY });
    return token;
  }

  /**
   * When entering the app we look for a token, if there is one already
   */
  async loadToken() {
    const token = await Storage.get({ key: TOKEN_KEY });
    if (token && token.value) {
      this.token = token.value;
      this.isAuthenticated.next(true);
      this.checkRole();
    } else {
      this.isAuthenticated.next(false);
    }
  }


  /**
   * Makes a login request, if the login is valid stores the token to plug it in the next api calls
   *
   * @param credentials
   */
  login(credentials: { email; password }): Observable<any> {
    return this.http.post(`${environment.api}/api/AuthManagement/login`, credentials).pipe(
      map((data: any) => data.token),
      switchMap(token =>
        from(Storage.set({ key: TOKEN_KEY, value: token }))
      ),
      switchMap(_=>this.checkRole()),
      tap(_ => {
        this.isAuthenticated.next(true);
      })
    );
  }

  checkRole() {
    return this.http.get(`${environment.api}/api/AuthManagement/userinfo`).pipe(
      map((data: any) => {
        this.role.next(data.role.toUpperCase());
        return data;
       })
    );
  }

  /**
   * Logout the user
   */
  async logout() {
    this.isAuthenticated.next(false);
    this.role.next(null);
    await Storage.remove({ key: TOKEN_KEY });
  }
}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { AuthGuard } from './guards/auth.guard';
import { MasterGuard } from './guards/master.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [AutoLoginGuard]
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'employees',
    loadChildren: () => import('./pages/employees/employees.module').then(m => m.EmployeesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'employees/new',
    loadChildren: () => import('./pages/employee-new/employee-new.module').then(m => m.EmployeeNewPageModule),
    canActivate: [AuthGuard]
    // canActivate: [AuthGuard, MasterGuard]
  },
  {
    path: 'employees/:id',
    loadChildren: () => import('./pages/employee-detail/employee-detail.module').then(m => m.EmployeeDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'services',
    loadChildren: () => import('./pages/services/services.module').then(m => m.ServicesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'services/new',
    loadChildren: () => import('./pages/services-new/services-new.module').then(m => m.ServicesNewPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'services/:id',
    loadChildren: () => import('./pages/services-detail/services-detail.module').then(m => m.ServicesDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customers',
    loadChildren: () => import('./pages/customers/customers.module').then(m => m.CustomersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customers/new',
    loadChildren: () => import('./pages/customers-new/customers-new.module').then(m => m.CustomersNewPageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'customers/:id',
    loadChildren: () => import('./pages/customers-detail/customers-detail.module').then(m => m.CustomersDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersPageModule),
    canActivate: [AuthGuard, MasterGuard]
  },
  {
    path: 'users/new',
    loadChildren: () => import('./pages/users-new/users-new.module').then(m => m.UsersNewPageModule),
    canActivate: [AuthGuard, MasterGuard]
  },
  {
    path: 'users/:id',
    loadChildren: () => import('./pages/users-detail/users-detail.module').then(m => m.UsersDetailPageModule),
    canActivate: [AuthGuard, MasterGuard]
  },
  {
    path: 'pending',
    loadChildren: () => import('./pages/pending/pending.module').then(m => m.PendingPageModule),
    canActivate: [AuthGuard, MasterGuard]
  },
  {
    path: 'materials',
    loadChildren: () => import('./pages/materials/materials.module').then(m => m.MaterialsPageModule),
    canActivate: [AuthGuard, MasterGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [];

  isLoggin = true;
  rol = null;

  constructor(private authService: AuthenticationService, private router: Router) {
    this.rol = this.authService.role;
  }

  ngOnInit() {
    this.authService.isAuthenticated.subscribe(data => {
      this.isLoggin = data;
      if (data) {
        this.authService.checkRole().subscribe((d: any) => {
          if (d.role === 'master') {
            this.appPages = [
              { title: 'Servicios', url: '/services', icon: 'briefcase' },
              { title: 'Clientes', url: '/customers', icon: 'id-card' },
              { title: 'Personal', url: '/employees', icon: 'people' },
              { title: 'Materiales', url: '/materials', icon: 'prism' },
              { title: 'Usuarios', url: '/users', icon: 'people-circle' },
              { title: 'Cambios', url: '/pending', icon: 'reader' },
              { title: 'Salir', url: '/folder/Outbox', icon: 'close' },
            ];
          } else {
            this.appPages = [
              { title: 'Servicios', url: '/services', icon: 'briefcase' },
              { title: 'Clientes', url: '/customers', icon: 'id-card' },
              { title: 'Personal', url: '/employees', icon: 'people' },
              { title: 'Salir', url: '/folder/Outbox', icon: 'close' },
            ];
          }
        }, err => {
          this.authService.logout();
          this.router.navigate(['/login']);
        });
      } else {
        this.appPages = [];
      }
    });
  }
  goToHome() {
    this.router.navigate(['services']);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'actionsPipe'
})
export class ActionsPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    switch (value) {
      case 'Update':
        return 'Actualizar';
      case 'Insert':
        return 'Crear';
      case 'Delete':
        return 'Borrar';
      default:
        return '-';
    }
  }

}

import { Address } from './../../models/address/address.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Province } from '../../models/province/province.model';
import { compare } from 'fast-json-patch';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private url = `${environment.api}/api/provinces/`;


  constructor(private http: HttpClient) {
  }
  static getProv(): any[] {
    return [
      { IdProvince: 0, Name: '' },
      { IdProvince: 1, Name: 'Araba/Álava' }, { IdProvince: 2, Name: 'Albacete' }, {
        IdProvince: 3,
        Name: 'Alicante/Alacant'
      }, { IdProvince: 4, Name: 'Almería' }, { IdProvince: 5, Name: 'Ávila' }, {
        IdProvince: 6,
        Name: 'Badajoz'
      }, { IdProvince: 7, Name: 'Balears, Illes' }, { IdProvince: 8, Name: 'Barcelona' }, {
        IdProvince: 9,
        Name: 'Burgos'
      }, { IdProvince: 10, Name: 'Cáceres' }, { IdProvince: 11, Name: 'Cádiz' }, {
        IdProvince: 12,
        Name: 'Castellón/Castelló'
      }, { IdProvince: 13, Name: 'Ciudad Real' }, { IdProvince: 14, Name: 'Córdoba' }, {
        IdProvince: 15,
        Name: 'Coruña, A'
      }, { IdProvince: 16, Name: 'Cuenca' }, { IdProvince: 17, Name: 'Girona' }, {
        IdProvince: 18,
        Name: 'Granada'
      }, { IdProvince: 19, Name: 'Guadalajara' }, { IdProvince: 20, Name: 'Gipuzkoa' }, {
        IdProvince: 21,
        Name: 'Huelva'
      }, { IdProvince: 22, Name: 'Huesca' }, { IdProvince: 23, Name: 'Jaén' }, {
        IdProvince: 24,
        Name: 'León'
      }, { IdProvince: 25, Name: 'Lleida' }, { IdProvince: 26, Name: 'Rioja, La' }, {
        IdProvince: 27,
        Name: 'Lugo'
      }, { IdProvince: 28, Name: 'Madrid' }, { IdProvince: 29, Name: 'Málaga' }, {
        IdProvince: 30,
        Name: 'Murcia'
      }, { IdProvince: 31, Name: 'Navarra' }, { IdProvince: 32, Name: 'Ourense' }, {
        IdProvince: 33,
        Name: 'Asturias'
      }, { IdProvince: 34, Name: 'Palencia' }, { IdProvince: 35, Name: 'Palmas, Las' }, {
        IdProvince: 36,
        Name: 'Pontevedra'
      }, { IdProvince: 37, Name: 'Salamanca' }, { IdProvince: 38, Name: 'Santa Cruz de Tenerife' }, {
        IdProvince: 39,
        Name: 'Cantabria'
      }, { IdProvince: 40, Name: 'Segovia' }, { IdProvince: 41, Name: 'Sevilla' }, {
        IdProvince: 42,
        Name: 'Soria'
      }, { IdProvince: 43, Name: 'Tarragona' }, { IdProvince: 44, Name: 'Teruel' }, {
        IdProvince: 45,
        Name: 'Toledo'
      }, { IdProvince: 46, Name: 'Valencia/València' }, { IdProvince: 47, Name: 'Valladolid' }, {
        IdProvince: 48,
        Name: 'Bizkaia'
      }, { IdProvince: 49, Name: 'Zamora' }, { IdProvince: 50, Name: 'Zaragoza' }, {
        IdProvince: 51,
        Name: 'Ceuta'
      }, { IdProvince: 52, Name: 'Melilla' }];
  }

  updateAddress(newAddress, oldAddress, id): Observable<any> {
    const old = oldAddress.Domicile ? JSON.parse(JSON.stringify(oldAddress.Domicile)) : {};
    console.log(newAddress, oldAddress);
    let newA = null;
    if (newAddress.value.Domicile === undefined) {
      newA = { Address: '', ZipCode: '', Municipality: '', IdProvince: 0 };
      return this.http.post(`${environment.api}/api/employees/${id}/domiciles`, newA);
    } else {
      newA = new Address(newAddress.value.Domicile);
      // Object.keys(newA).map(d => {
      //   if (newA[d] === null || newA[d] === '' || newA[d] === 0) {
      //     delete newA[d];
      //   }
      // });
      if (newA.IdDomicile !== undefined) {
        const patch = compare(old, newA);
        if (patch.length > 0) {
          return this.http.patch(`${environment.api}/api/domiciles/${newAddress.value.Domicile.IdDomicile}`, patch);
        }
        return of([]);
      } else {
        return this.http.post(`${environment.api}/api/employees/${id}/domiciles`, newA);
      }
    }
  }

  public getProvinces() {
    return of(AddressService.getProv());
  }
}

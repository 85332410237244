
export class Address {
  IdDomicile: string;
  Address: string;
  ZipCode: string;
  Municipality: string;
  IdProvince: number;

  constructor(data: any) {
    this.IdDomicile = data.IdDomicile;
    this.Address = data.Address ? data.Address : '';
    this.ZipCode = data.ZipCode ? data.ZipCode : '';
    this.Municipality = data.Municipality ? data.Municipality : '';
    this.IdProvince = data.IdProvince ? data.IdProvince : 0;
  }

}

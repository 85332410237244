import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {AuthenticationService} from '../services/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(req, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    let token = null;
    await AuthenticationService.getToken().then(t => token = t);
    const authReq = req.clone({
      setHeaders: {
        authorization: 'Bearer ' + token.value
      }
    });
    return next.handle(authReq).toPromise();
  }
}
